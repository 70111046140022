import { template as template_d9361ff06f4f4f939963c4cc8ba4afa2 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_d9361ff06f4f4f939963c4cc8ba4afa2(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
