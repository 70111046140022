import { template as template_cab3d6c0eebc4c60a298c8392b41611f } from "@ember/template-compiler";
const WelcomeHeader = template_cab3d6c0eebc4c60a298c8392b41611f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
