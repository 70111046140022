import { template as template_6d3dd3e4b97d4ae9ad934a60bc013bce } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_6d3dd3e4b97d4ae9ad934a60bc013bce(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
